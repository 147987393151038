$media-tablet: 768px;
$media-desktop: 1280px;
$media-max-content: 1536px;

@mixin tablet {
  @media screen and (min-width: $media-tablet) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $media-desktop) {
    @content;
  }
}
