@use "../../styles/partials/colors" as *;
@use "../../styles/partials/media" as *;

@keyframes skill-hover {
  0% {
    transform: scale(1);
    box-shadow: 1.6px 3.2px 3.2px rgba($primary-black, 0.625);
  }
  100% {
    transform: scale(1.05);
    box-shadow: 1.8px 3.5px 3.5px rgba($primary-black, 0.75);
  }
}
@keyframes skill-hover-off {
  0% {
    transform: scale(1.05);
    box-shadow: 1.8px 3.5px 3.5px rgba($primary-black, 0.75);
  }
  100% {
    transform: scale(1);
    box-shadow: 1.6px 3.2px 3.2px rgba($primary-black, 0.625);
  }
}

.portfolio {
  max-width: $media-max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &-container {
    width: 100%;
    height: 100%;
    background-color: $primary-blue;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.625));
    border-radius: 4px;
    color: white;
    position: relative;
    z-index: 1;
    padding: 1rem;
    display: flex;
    justify-content: center;
    @include tablet {
      padding: 2.5rem;
    }
    @include desktop {
      padding: 0 auto;
    }
  }

  &__scroll {
    display: none;
    z-index: 99;
    outline: none;
    cursor: pointer;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    background-color: rgba($primary-black, 0.8125);
    color: $primary-white;
    border: 2px solid rgba($primary-white, 0.75);
    border-radius: 100px;
    padding: 0.125rem 0.125rem 0.125rem 0.8125rem;
    align-items: center;
    @include tablet {
      transform: scale(1.075);
    }
    @include desktop {
      right: 2.5rem;
      transform: scale(1.25);
    }

    &:hover {
      color: $primary-orange;
      border: 2px solid rgba($primary-orange, 0.6255);
      background-color: rgba($primary-white, 0.875);
      .portfolio__arrow {
        filter: brightness(0) saturate(100%) invert(71%) sepia(94%)
          saturate(3201%) hue-rotate(3deg) brightness(106%) contrast(104%);
      }
    }

    &-show {
      display: flex;
    }
  }

  &__arrow {
    transform: rotate(270deg);
    height: 22px;
    margin-left: 0.25rem;
    filter: brightness(100);
  }

  &__greeting {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
    @include tablet {
      flex-direction: row;
      gap: 2.5rem;
    }
  }

  &__avatar {
    width: 7.5rem;
    border-radius: 4px;
  }

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__paras {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 0.75rem;
    margin-bottom: 1rem;
    @include desktop {
      max-width: 75rem;
    }
  }

  &__title {
    text-align: center;
    width: 100%;
    @include desktop {
      margin: 1rem 0 0.5rem;
    }
  }

  &__para {
    font-size: 0.75rem;
    font-weight: 600;
    font-style: italic;
    @include tablet {
      font-size: 0.875rem;
    }
    @include desktop {
      font-size: 1.125rem;
    }
  }

  &__skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 1.25rem;
    margin-bottom: 1.75rem;
    @include tablet {
      gap: 0.325rem 2rem;
    }
  }

  &__skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
    width: 25%;
    min-height: 7.125rem;
    margin-top: 0.625rem;
    padding: 1rem;
    border: 1px solid rgba($primary-black, 0.125);
    border-radius: 4px;
    box-shadow: 1.6px 3.2px 3.2px rgba($primary-black, 0.625);
    animation: skill-hover-off 0.5s;
    background-color: rgba($primary-white, 0.025);
    @include tablet {
      gap: 0.75rem;
      width: 15%;
    }
    @include desktop {
      gap: 1rem;
    }

    &:hover {
      animation: skill-hover 0.5s both;
    }
  }

  &__image {
    width: 75%;
    min-width: 2.5rem;
    max-width: 6.25rem;
    margin: auto 0;
    border-radius: 6px;
  }

  &__caption {
    text-transform: uppercase;
    font-size: 0.75rem;
    @include tablet {
      font-size: 1rem;
    }
    @include desktop {
      font-size: 1.25rem;
    }

    &--long {
      font-size: 0.59375rem;
      @include tablet {
        font-size: 0.875rem;
      }
      @include desktop {
        font-size: 1.25rem;
      }
    }
  }

  &__projects {
    padding: 0 0.75rem;
    margin-bottom: 1rem;
  }

  &__showcase {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    &-card {
      margin-bottom: 0.75rem;
      @include tablet {
        display: flex;
        flex-wrap: wrap;
        gap: 0.25rem 1.5rem;
        justify-content: center;
        align-items: center;
      }
      @include desktop {
        gap: 0.5rem 2.5rem;
        margin: 1.125rem 0;
      }
    }

    &-image {
      width: 100%;
      border-radius: 4px;
      margin: 0.25rem 0;
      box-shadow: -2px -2px 8px 2px red, 2px 2px 8px 2px blue;
      @include tablet {
        width: 52.5%;
        max-width: 25rem;
      }
      @include desktop {
        max-width: 27.5rem;
      }
    }

    &-description {
      @include tablet {
        width: 40%;
      }
      @include desktop {
        width: 42.5%;
      }
    }

    &-actions {
      display: flex;
      justify-content: center;
      gap: 1.25rem;
      margin-top: 0.375rem;
      @include tablet {
        margin-top: 0.75rem;
      }
      @include desktop {
        margin-top: 1.125rem;
      }
    }

    &-cta {
      display: block;
      color: $primary-white;
      border: 2px solid rgba($primary-white, 0.75);
      border-radius: 4px;
      padding: 0.125rem 0.75rem;

      &:hover {
        color: $primary-orange;
        border: 2px solid rgba($primary-orange, 0.75);
        background-color: $primary-white;
        transform: scale(1.125);
      }
    }
  }

  &__bootcamp {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    @include tablet {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      gap: 2.5rem;
    }
    @include desktop {
      justify-content: space-evenly;
      gap: 0;
    }

    &-card {
      margin-bottom: 0.75rem;
      @include tablet {
        width: 50%;
        max-width: 30rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &-image {
      width: 100%;
      border-radius: 4px;
      margin: 0.25rem 0;
      box-shadow: 0 0 6px 3px rgba($primary-orange, 0.3125);
      @include tablet {
        max-width: 21.25rem;
      }
      @include desktop {
        max-width: 23.75rem;
      }
    }

    &-description {
      @include tablet {
        padding: 0 0.75rem;
        margin-top: 0.375rem;
      }
      @include desktop {
        margin-top: 0.875rem;
      }
    }

    &-actions {
      display: flex;
      justify-content: center;
      gap: 1.25rem;
      margin-top: 0.375rem;
      @include tablet {
        margin-top: 0.75rem;
      }
      @include desktop {
        margin-top: 1.125rem;
      }
    }

    &-cta {
      display: block;
      color: $primary-white;
      border: 2px solid rgba($primary-white, 0.75);
      border-radius: 4px;
      padding: 0.125rem 0.75rem;

      &:hover {
        color: $primary-orange;
        border: 2px solid rgba($primary-orange, 0.75);
        background-color: $primary-white;
        transform: scale(1.125);
      }
    }
  }

  &__subtitle {
    margin: 0.625rem 0 0.25rem;
    font-size: 1.125rem;
    color: $off-white;
    text-align: center;
    @include tablet {
      font-size: 1.375rem;
      width: 100%;
    }
    @include desktop {
      &--hide {
        display: none;
      }
    }

    &-showcase {
      display: none;
      margin: 0.625rem 0 0.25rem;
      color: $off-white;
      text-align: center;
      font-size: 1.375rem;
      width: 100%;

      @include desktop {
        display: block;
        margin-bottom: 0.5rem;
      }
    }
  }
}
