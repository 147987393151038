@font-face {
  font-family: "WorkSans";
  src: url("../../assets/fonts/WorkSans-Regular.woff2") format("woff2"),
    url("../../assets/fonts/WorkSans-Regular.woff") format("woff"),
    url("../../assets/fonts/WorkSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "WorkSans";
  src: url("../../assets/fonts/WorkSans-Italic.woff2") format("woff2"),
    url("../../assets/fonts/WorkSans-Italic.woff") format("woff"),
    url("../../assets/fonts/WorkSans-Italic.ttf") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "WorkSans";
  src: url("../../assets/fonts/WorkSans-ExtraLight.woff2") format("woff2"),
    url("../../assets/fonts/WorkSans-ExtraLight.woff") format("woff"),
    url("../../assets/fonts/WorkSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "WorkSans";
  src: url("../../assets/fonts/WorkSans-SemiBold.woff2") format("woff2"),
    url("../../assets/fonts/WorkSans-SemiBold.woff") format("woff"),
    url("../../assets/fonts/WorkSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
