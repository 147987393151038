@use "./styles/partials/colors" as *;
@use "./styles/partials/media" as *;
@use "./styles/partials/typography" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "WorkSans", sans-serif;
  color: $primary-white;
  background-color: $primary-black;
  min-width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin dots($count) {
  $text-shadow: ();
  @for $i from 0 through $count {
    $text-shadow: $text-shadow,
      (-0.5 + (random()) * 5) +
        rem
        (-0.5 + (random()) * 2) +
        rem
        2px
        hsla(random() * 360, 100%, 50%, 0.95);
  }
  text-shadow: $text-shadow;
}

body,
head {
  display: block;
  color: transparent;
}

head::before,
head::after,
body::before,
body::after {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 3em;
  min-height: 3em;
  content: "V";
  animation: 44s -27s move infinite ease-in-out alternate;
}

body::before {
  @include dots(50);
  animation-duration: 44s;
  animation-delay: -27s;
}

body::after {
  @include dots(100);
  animation-duration: 43s;
  animation-delay: -32s;
}

head::before {
  @include dots(100);
  animation-duration: 42s;
  animation-delay: -23s;
}

head::after {
  @include dots(100);
  animation-duration: 41s;
  animation-delay: -19s;
}

@keyframes move {
  from {
    transform: rotate(0deg) scale(15) translateX(-20px);
  }
  to {
    transform: rotate(360deg) scale(22) translateX(20px);
  }
}

#root {
  min-width: 100vw;
  height: fit-content;
  min-height: 100vh;
  padding: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @include tablet {
    padding: 0.5rem;
  }
  @include desktop {
    padding: 0.625rem;
  }
}

h1 {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  @include tablet {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
  @include desktop {
    font-size: 2.625rem;
  }
}

h2 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: $primary-orange;
  text-transform: uppercase;
  @include tablet {
    font-size: 2rem;
    line-height: 2rem;
  }
  @include desktop {
    font-size: 2.325rem;
  }
}

h3 {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 600;
  @include tablet {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

p,
label,
span,
a {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
  @include tablet {
    font-size: 1.25rem;
  }
}

ul,
li {
  list-style: none;
}
