@use "../../styles/partials/colors" as *;
@use "../../styles/partials/media" as *;

@keyframes link-hover {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.125);
  }
}
@keyframes link-hover-off {
  0% {
    transform: scale(1.125);
  }
  100% {
    transform: scale(1);
  }
}

.link-bar {
  &__links {
    display: flex;
    gap: 0.75rem;
    @include tablet {
      gap: 1.375rem;
    }
    @include desktop {
      gap: 1.875rem;
    }

    &:first-of-type {
      margin-bottom: 1rem;
    }
  }

  &__link {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    @include tablet {
      width: 3.25rem;
      height: 3.25rem;
    }
    @include desktop {
      width: 4rem;
      height: 4rem;
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      animation: link-hover-off 0.5s;

      &:hover {
        animation: link-hover 0.5s both;
      }
    }

    &-label {
      display: none;
      color: $primary-white;
      position: absolute;
      @include tablet {
        display: block;
        font-size: 0.875rem;
        line-height: 1.5rem;
        top: calc(-1 * 26px);
      }
      @include desktop {
        font-size: 1rem;
        line-height: 1.75rem;
        top: calc(-1 * 28px);
      }

      &--bottom {
        display: none;
        color: $primary-white;
        position: absolute;
        @include tablet {
          display: block;
          font-size: 0.875rem;
          line-height: 1.5rem;
          bottom: calc(-1 * 26px);
        }
        @include desktop {
          font-size: 1rem;
          line-height: 1.75rem;
          bottom: calc(-1 * 28px);
        }
      }
    }

    &-background {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background-color: $primary-white;
      position: absolute;
      transform: scale(0.95);
      z-index: -1;
      @include tablet {
        width: 3.25rem;
        height: 3.25rem;
      }
      @include desktop {
        width: 4rem;
        height: 4rem;
      }
    }
  }
}
