$primary-blue: #1b4571;
$light-blue: #949ca4;

$primary-orange: #ffa500;

$primary-white: #ffffff;
$off-white: antiquewhite;

$primary-black: #000000;

$primary-gray: #e1e1e1;
